:root {
    --para-color: #888;
    --heading-color: #2d2d43;
    --theme-light: #efefef;
    --primary-color: $primary;
    --secondary-color: #222042;
    --secondary-light: #2e2e51;
    --border-radius: 8px;
}

.noscroll {
    overflow: hidden;
    height: 100vh;
}

html {
    scroll-behavior: smooth;
}

body,
html {
    margin: 0;
    padding: 0;
    font-family: "Open Sans", sans-serif;
}

body a,
button,
.btn {
    cursor: pointer !important;
    font-family: "Cabin", sans-serif;
}

.clear {
    clear: both;
}

.img-responsive {
    max-width: 100%;
    display: block;
}

.d-grid {
    display: grid;
}

.align-self {
    align-self: center;
}

button,
input,
select,
label,
li {
    -webkit-appearance: none;
    outline: none;
    font-family: "Cabin", sans-serif;
}

body a,
button,
.btn {
    cursor: pointer !important;
}

a,
a:hover {
    text-decoration: none;
}

iframe {
    border: none;
    display: block;
}

ul {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    color: var(--heading-color);
    font-family: "Cabin", sans-serif;
}

p {
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 28px;
    color: var(--para-color);
}

li {
    list-style-type: none;
}

p.white {
    color: #eee;
}

.align-center {
    align-self: center;
}

.noscroll {
    min-height: 100vh;
    overflow: hidden;
}

.radius-image {
    border-radius: var(--border-radius);
}

/*-- container --*/
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 100%;
        padding-right: 30px;
        padding-left: 30px;
        margin-right: auto;
        margin-left: auto;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 100%;
        padding-right: 25px;
        padding-left: 25px;
        margin-right: auto;
        margin-left: auto;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 100%;
        padding-right: 25px;
        padding-left: 25px;
        margin-right: auto;
        margin-left: auto;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 100%;
        padding-right: 25px;
        padding-left: 25px;
        margin-right: auto;
        margin-left: auto;
    }
}

@media (min-width: 1280px) {
    .container {
        max-width: 1200px;
    }
}

/*-- //container --*/
.btn-style {
    padding: 12px 30px;
    color: #fff;
    font-size: 17px;
    line-height: 28px;
    text-transform: capitalize;
    font-weight: 600;
    transition: 0.3s ease-in;
    border-radius: 6px;
    width: 175px;
    white-space: nowrap;
}

.btn-outline-style {
    padding: 10px 30px;
    font-size: 17px;
    line-height: 28px;
    text-transform: capitalize;
    font-weight: 600;
    transition: 0.3s ease-in;
    border-radius: 6px;
}

.transparent-btn {
    background: rgba(255, 255, 255, 0.16);
}

.transparent-btn:hover {
    background: rgba(255, 255, 255, 0.25);
    color: #fff;
}

.grids-gap {
    padding-top: 20px;
}

h3.title-small {
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 20px;
}

h3.title-big {
    font-size: 35px;
    font-weight: 600;
    line-height: 42px;
    margin-bottom: 20px;
    color: $lite;
}

@media (max-width: 768px) {
    h3.title-big {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 10px;
        color: $lite;
    }
}

@media (max-width: 736px) {
    h3.title-small {
        font-size: 20px;
        line-height: 30px;
    }
}

.breadcrumb-bg-about {
    background-image: linear-gradient(to right, rgba(25, 25, 25, 0.7), rgba(0, 0, 0, 0.7)), url("../images/gallery/cover-image.jpg");
    background-size: cover;
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-position: center;
    background-attachment: fixed;
    height: 400px;
}

.breadcrumb-bg-services {
    background-size: cover;
    background-image: linear-gradient(to right, rgba(25, 25, 25, 0.7), rgba(0, 0, 0, 0.7)), url("../images/gallery/cover-image.jpg");
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-position: center;
    background-attachment: fixed;
    height: 400px;
}

.breadcrumb-bg-blog {
    background-size: cover;
    background-image: linear-gradient(to right, rgba(25, 25, 25, 0.7), rgba(0, 0, 0, 0.7)), url("../images/gallery/cover-image.jpg");
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-position: center;
    background-attachment: fixed;
    height: 400px;
}

.breadcrumb-bg-blog-single {
    background-size: cover;
    background-image: linear-gradient(to right, rgba(25, 25, 25, 0.7), rgba(0, 0, 0, 0.7)), url("../images/gallery/cover-image.jpg");
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-position: center;
    background-attachment: fixed;
    height: 300px;
}

.breadcrumb-bg-contact {
    background-size: cover;
    background-image: linear-gradient(to right, rgba(25, 25, 25, 0.7), rgba(0, 0, 0, 0.7)), url("../images/gallery/cover-image.jpg");
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-position: center;
    background-attachment: fixed;
    height: 400px;
}

h2.title {
    color: #fff;
    font-size: 60px;
    font-weight: 600;
    text-align: center;
    font-family: 'Dancing Script', sans-serif;
    margin-top: 100px;
}

.w3l-breadcrumb {
    background-color: #f3f3f3;
}

.breadcrumbs-custom-path {
    padding: 20px 0;
    text-align: center;
}

.breadcrumbs-custom-path li {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: capitalize;
}

.breadcrumbs-custom-path li span.fa {
    font-size: 12px;
}

.breadcrumbs-custom-path a,
.breadcrumbs-custom-path a:active,
.breadcrumbs-custom-path a:focus {
    color: #777;
}

.breadcrumbs-custom-path li.active {
    font-weight: 900;
    color: #151515;
}

@media (max-width: 568px) {
    h2.title {
        font-size: 40px;
        margin-top: 30%;
    }
}

.w3l-header-nav {
    background-color: $lite;
    padding: 10px 0;
}

.w3l-header-nav .dropdown-toggle::after {
    display: none;
}

.w3l-header-nav .navbar-nav .nav-link {
    background: none;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 400;
    display: block;
    line-height: 28px;
    padding: 0 15px;
}

.w3l-header-nav .box-support p {
    font-size: 14px;
    color: $lite;
    font-weight: 600;
}

.w3l-header-nav .box-support a {
    color: $lite !important;
}

.w3l-header-nav .box-support a:hover {
    color: $primary-dark !important;
}

.w3l-header-nav .dropdown-item {
    background: transparent;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 400;
    display: block;
    line-height: 26px;
    padding: 5px 15px;
    color: rgba(0, 0, 0, 0.5);
}

.w3l-header-nav .dropdown-item:hover {
    color: rgba(0, 0, 0, 0.9);
}

.w3l-header-nav a.dropdown-item.active {
    color: rgba(0, 0, 0, 0.9);
}

.w3l-header-nav .navbar-light a.navbar-brand {
    color: $primary;
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    padding: 0;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    margin: 0;
    position: relative;
    background: transparent;
    margin-left: 10px;
}

.w3l-header-nav .navbar-light a.navbar-brand span {
    font-weight: 100;
    color: var(--heading-color);
}

.w3l-header-nav .navbar-light a.navbar-brand:after {
    content: "";
    position: absolute;
    /* border: 3px solid var(--primary-color); */
    width: 60px;
    left: -15px;
    top: -11px;
    bottom: -11px;
    z-index: -1;
    border-radius: 8px;
}

.w3l-header-nav button.navbar-toggler .icon-close {
    display: block;
}

.w3l-header-nav button.navbar-toggler .icon-expand {
    display: none;
}

.w3l-header-nav button.navbar-toggler.collapsed .icon-close {
    display: none;
}

.w3l-header-nav button.navbar-toggler.collapsed .icon-expand {
    display: block;
}

.w3l-header-nav form.search-box {
    display: flex;
}

.w3l-header-nav .search-right {
    margin: 0 25px 0 15px;
}

.w3l-header-nav .search-right a {
    width: 40px;
    height: 40px;
    display: block;
    line-height: 40px;
    background: #e3e3e3;
    background: rgba(234, 58, 96, 0.2);
    text-align: center;
    border-radius: 50%;
    color: #fff;
}

.w3l-header-nav .search-right a:hover {
    background: var(--primary-color);
}

.w3l-header-nav .search-right .popup form input[type="search"] {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #555;
    border: none;
    height: 60px;
    border-radius: 6px;
    background: #fff;
    padding: 0 20px;
    width: 100%;
    border-radius: 0;
    -webkit-border-top-left-radius: 6px;
    -webkit-border-bottom-left-radius: 6px;
    -moz-border-radius-topleft: 6px;
    -moz-border-radius-bottomleft: 6px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.w3l-header-nav .search-right .popup form button {
    border: none;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    color: var(--primary-color);
    width: 60px;
    background: #fff;
    line-height: 35px;
    display: inline-block;
    border-radius: 6px;
    transition: 0.5s ease-out;
    font-weight: 700;
    display: block;
    height: 60px;
    margin: 0 auto;
    border-radius: 0;
    -webkit-border-top-right-radius: 6px;
    -webkit-border-bottom-right-radius: 6px;
    -moz-border-radius-topright: 6px;
    -moz-border-radius-bottomright: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.w3l-header-nav .search-right .popup form button:hover {
    background: var(--primary-color);
    color: #fff;
    opacity: 0.8;
    outline: none;
    border: none;
}

.about-description {
    color: $lite !important;
    font-size: 20px;
    text-align: left;
}

.about-title {
    color: $primary !important;
    margin-bottom: 20px;
}

.about-sub-title {
    color: $primary;
    margin-bottom: 20px;
}

.w3l-header-nav .search-right .popup form input:focus {
    outline: none;
    border: none;
}

.w3l-header-nav .search-right .pop-overlay {
    position: fixed;
    top: 0px;
    left: 0;
    right: 0;
    transition: opacity 0ms;
    visibility: hidden;
    opacity: 0;
    z-index: 99;
    background: rgba(25, 23, 23, 0.81);
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.85);
    height: 100vh;
}

.w3l-header-nav .search-right .pop-overlay:target {
    visibility: visible;
    opacity: 1;
}

.w3l-header-nav .search-right .popup {
    margin: 0rem auto;
    padding: 40px 50px;
    max-width: 700px;
    border-radius: 3px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.w3l-header-nav .search-right .search-top {
    display: grid;
    grid-template-columns: auto 1fr 1fr;
    grid-gap: 30px;
    margin-top: 24px;
}

.w3l-header-nav .search-right .search-top h4 {
    font-size: 20px;
    color: #fff;
    margin-bottom: 16px;
}

.w3l-header-nav .search-right .popup h3 {
    font-size: 6px;
    color: #fff;
    margin-bottom: 16px;
}

.w3l-header-nav .search-right .search-top span.fa {
    padding-right: 10px;
}

.w3l-header-nav .search-right .search-top li {
    display: block;
}

.w3l-header-nav .search-right .search-top li a {
    font-size: 16px;
    color: #fff;
    line-height: 28px;
}

.w3l-header-nav .search-right .search-top li a:hover {
    color: #fff;
}

.w3l-header-nav .search-right .close {
    position: absolute;
    top: 20px;
    right: 40px;
    transition: all 200ms;
    font-size: 40px;
    text-decoration: none;
    color: #fff;
    opacity: 1;
    background: none;
}

.w3l-header-nav .search-right .close:hover {
    opacity: 0.8;
    background: none;
}

@media (max-width: 1200px) {

}


@media (max-width: 991px) {
    .w3l-header-nav .navbar-nav .nav-link {
        padding: 5px 15px;
    }
    .w3l-header-nav .dropdown-menu {
        margin: 5px 15px;
    }
    .w3l-header-nav .navbar-light .navbar-toggler {
        border-color: transparent;
        outline: none;
        padding: 0;
    }
    .w3l-header-nav .navbar-light .navbar-toggler span.fa {
        font-size: 24px;
        color: $lite;
    }
    .w3l-header-nav .navbar-collapse {
        max-height: calc(100vh - 100px);
        overflow: auto;
        background: $lite;
        border-radius: 10px;
        padding: 15px 5px 6px;
    }
}

.w3l-main-slider h5.banner-text {
    font-size: 20px;
    line-height: 30px;
    color: #fff;
    margin-bottom: 8px;
    text-transform: uppercase;
    max-width: 950px;
    letter-spacing: 1px;
}

.w3l-main-slider h3.banner-text {
    line-height: 70px;
    font-weight: 700;
    font-size: 60px;
    color: #fff;
    margin-bottom: 15px;
    max-width: 500px;
    font-family: 'Dancing Script', serif;

}

.w3l-main-slider p {
    color: #fff;
    max-width: 600px;
    margin-bottom: 40px;
    font-family: "Open Sans", sans-serif;
}

.w3l-main-slider .banner-slider {
    position: relative;
    z-index: 1;
}

.w3l-main-slider .slider-info a img {
    transition: 0.3s ease-in;
    -webkit-transition: 0.3s ease-in;
    -o-transition: 0.3s ease-in;
    -moz-transition: 0.3s ease-in;
    -ms-transition: 0.3s ease-in;
}

.w3l-main-slider .banner-view {
    //background: url() no-repeat center;
    background-size: cover;
    min-height: 700px;
    position: relative;
    z-index: 0;
    display: grid;
    align-items: center;
    height: 80vh;
}

.w3l-main-slider .banner-top1 {
    //background: url("../images/gallery/slide2.jpg") no-repeat center;
    background-size: cover;
}

.w3l-main-slider .banner-top2 {
    //background: url("../images/gallery/slide3.jpg") no-repeat center;
    background-size: cover;
}

.w3l-main-slider .banner-top3 {
    //background: url("../images/gallery/slide4.jpg") no-repeat center;
    background-size: cover;
}

.w3l-main-slider .banner-view:before {
    content: "";
    background: rgba(39, 44, 47, 0.45);
    position: absolute;
    top: 0;
    min-height: 100%;
    left: 0;
    right: 0;
    z-index: -1;
}

.w3l-main-slider .csslider > input {
    display: none;
}

.w3l-main-slider .csslider > input:nth-of-type(10):checked ~ ul li:first-of-type {
    margin-left: -900%;
}

.w3l-main-slider .csslider > input:nth-of-type(9):checked ~ ul li:first-of-type {
    margin-left: -800%;
}

.w3l-main-slider .csslider > input:nth-of-type(8):checked ~ ul li:first-of-type {
    margin-left: -700%;
}

.w3l-main-slider .csslider > input:nth-of-type(7):checked ~ ul li:first-of-type {
    margin-left: -600%;
}

.w3l-main-slider .csslider > input:nth-of-type(6):checked ~ ul li:first-of-type {
    margin-left: -500%;
}

.w3l-main-slider .csslider > input:nth-of-type(5):checked ~ ul li:first-of-type {
    margin-left: -400%;
}

.w3l-main-slider .csslider > input:nth-of-type(4):checked ~ ul li:first-of-type {
    margin-left: -300%;
}

.w3l-main-slider .csslider > input:nth-of-type(3):checked ~ ul li:first-of-type {
    margin-left: -200%;
}

.w3l-main-slider .csslider > input:nth-of-type(2):checked ~ ul li:first-of-type {
    margin-left: -100%;
}

.w3l-main-slider .csslider > input:nth-of-type(1):checked ~ ul li:first-of-type {
    margin-left: 0%;
}

.w3l-main-slider .csslider > ul {
    position: relative;
    z-index: 1;
    font-size: 0;
    line-height: 0;
    margin: 0 auto;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

.w3l-main-slider .csslider > ul > li {
    position: relative;
    display: inline-block;
    width: 100%;
    overflow: visible;
    font-size: 15px;
    font-size: initial;
    line-height: normal;
    transition: all 0.5s cubic-bezier(0.4, 1.3, 0.65, 1);
    vertical-align: top;
    box-sizing: border-box;
    white-space: normal;
}

.w3l-main-slider .csslider > ul > li.scrollable {
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.w3l-main-slider .csslider > .navigation {
    z-index: 10;
    font-size: 0;
    line-height: 0;
    text-align: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-top: 1px solid rgba(255, 255, 255, 0.22);
    background: transparent;
    position: absolute;
    bottom: 10px;
    width: 100%;
    left: 0;
}

.w3l-main-slider .page-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    cursor: pointer;
}

.w3l-main-slider .csslider > .navigation label {
    position: relative;
    display: inline-block;
    cursor: pointer;
    padding: 30px 20px;
    /* height: 11px; */
    transition: 0.5s all;
    -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -o-transition: 0.5s all;
    -ms-transition: 0.5s all;
    color: #fff;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
}

.w3l-main-slider .csslider > .navigation label:hover:after {
    opacity: 1;
}

.w3l-main-slider .csslider > .navigation label:after {
    content: "";
    position: absolute;
    left: 0%;
    top: 0px;
    /* margin-left: -6px; */
    /* margin-top: -6px; */
    opacity: 0;
    width: 100%;
    /* height: 13px; */
    border-top: 2px var(--theme-white) solid;
    transition: 0.5s all;
    -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -o-transition: 0.5s all;
    -ms-transition: 0.5s all;
    height: 100%;
    opacity: 0.4;
}

.w3l-main-slider .csslider > .arrows {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.w3l-main-slider .csslider.inside .navigation {
    bottom: 10px;
    margin-bottom: 10px;
}

.w3l-main-slider .csslider.inside .navigation label {
    border: 1px solid #7e7e7e;
}

.w3l-main-slider .csslider > input:nth-of-type(1):checked ~ .navigation label:nth-of-type(1):after,
.w3l-main-slider .csslider > input:nth-of-type(2):checked ~ .navigation label:nth-of-type(2):after,
.w3l-main-slider .csslider > input:nth-of-type(3):checked ~ .navigation label:nth-of-type(3):after,
.w3l-main-slider .csslider > input:nth-of-type(4):checked ~ .navigation label:nth-of-type(4):after,
.w3l-main-slider .csslider > input:nth-of-type(5):checked ~ .navigation label:nth-of-type(5):after,
.w3l-main-slider .csslider > input:nth-of-type(6):checked ~ .navigation label:nth-of-type(6):after,
.w3l-main-slider .csslider > input:nth-of-type(7):checked ~ .navigation label:nth-of-type(7):after,
.w3l-main-slider .csslider > input:nth-of-type(8):checked ~ .navigation label:nth-of-type(8):after,
.w3l-main-slider .csslider > input:nth-of-type(9):checked ~ .navigation label:nth-of-type(9):after,
.w3l-main-slider .csslider > input:nth-of-type(10):checked ~ .navigation label:nth-of-type(10):after,
.w3l-main-slider .csslider > input:nth-of-type(11):checked ~ .navigation label:nth-of-type(11):after {
    opacity: 1;
}

.w3l-main-slider .csslider > .arrows {
    position: absolute;
    bottom: 48%;
    width: 100%;
    height: 26px;
    z-index: 1;
    box-sizing: content-box;
}

.w3l-main-slider .csslider > .arrows label {
    display: none;
    position: absolute;
    padding: 8px;
    box-shadow: inset 2px -2px 0 1px #fff;
    cursor: pointer;
    transition: box-shadow 0.15s, margin 0.15s;
}

.w3l-main-slider .csslider > .arrows label:hover {
    box-shadow: inset 3px -3px 0 2px #fff;
    margin: 0 0px;
}

.w3l-main-slider .csslider > .arrows label:before {
    content: "";
    position: absolute;
    top: -100%;
    left: -100%;
    height: 300%;
    width: 300%;
}

.w3l-main-slider .csslider.infinity > input:first-of-type:checked ~ .arrows label.goto-last,
.w3l-main-slider .csslider > input:nth-of-type(1):checked ~ .arrows > label:nth-of-type(0),
.w3l-main-slider .csslider > input:nth-of-type(2):checked ~ .arrows > label:nth-of-type(1),
.w3l-main-slider .csslider > input:nth-of-type(3):checked ~ .arrows > label:nth-of-type(2),
.w3l-main-slider .csslider > input:nth-of-type(4):checked ~ .arrows > label:nth-of-type(3),
.w3l-main-slider .csslider > input:nth-of-type(5):checked ~ .arrows > label:nth-of-type(4),
.w3l-main-slider .csslider > input:nth-of-type(6):checked ~ .arrows > label:nth-of-type(5),
.w3l-main-slider .csslider > input:nth-of-type(7):checked ~ .arrows > label:nth-of-type(6),
.w3l-main-slider .csslider > input:nth-of-type(8):checked ~ .arrows > label:nth-of-type(7),
.w3l-main-slider .csslider > input:nth-of-type(9):checked ~ .arrows > label:nth-of-type(8),
.w3l-main-slider .csslider > input:nth-of-type(10):checked ~ .arrows > label:nth-of-type(9),
.w3l-main-slider .csslider > input:nth-of-type(11):checked ~ .arrows > label:nth-of-type(10) {
    display: block;
    left: 40px;
    top: 4px;
    transform: rotate(45deg);
}

.w3l-main-slider .csslider.infinity > input:last-of-type:checked ~ .arrows label.goto-first,
.w3l-main-slider .csslider > input:nth-of-type(1):checked ~ .arrows > label:nth-of-type(2),
.w3l-main-slider .csslider > input:nth-of-type(2):checked ~ .arrows > label:nth-of-type(3),
.w3l-main-slider .csslider > input:nth-of-type(3):checked ~ .arrows > label:nth-of-type(4),
.w3l-main-slider .csslider > input:nth-of-type(4):checked ~ .arrows > label:nth-of-type(5),
.w3l-main-slider .csslider > input:nth-of-type(5):checked ~ .arrows > label:nth-of-type(6),
.w3l-main-slider .csslider > input:nth-of-type(6):checked ~ .arrows > label:nth-of-type(7),
.w3l-main-slider .csslider > input:nth-of-type(7):checked ~ .arrows > label:nth-of-type(8),
.w3l-main-slider .csslider > input:nth-of-type(8):checked ~ .arrows > label:nth-of-type(9),
.w3l-main-slider .csslider > input:nth-of-type(9):checked ~ .arrows > label:nth-of-type(10),
.w3l-main-slider .csslider > input:nth-of-type(10):checked ~ .arrows > label:nth-of-type(11),
.w3l-main-slider .csslider > input:nth-of-type(11):checked ~ .arrows > label:nth-of-type(12) {
    display: block;
    right: 40px;
    top: 4px;
    left: auto;
    transform: rotate(225deg);
}

.w3l-main-slider #slider1 > input:nth-of-type(1):checked ~ ul #bg,
.w3l-main-slider #slider1 > input:nth-of-type(2):checked ~ ul #bg1,
.w3l-main-slider #slider1 > input:nth-of-type(3):checked ~ ul #bg2,
.w3l-main-slider #slider1 > input:nth-of-type(4):checked ~ ul #bg3 {
    width: 100%;
    transition: 0.5s 0.5s;
    text-align: center;
    padding: 0;
    height: 100%;
}

.w3l-main-slider #slider1 > input:nth-of-type(1):checked ~ ul #bg div,
.w3l-main-slider #slider1 > input:nth-of-type(2):checked ~ ul #bg1 div,
.w3l-main-slider #slider1 > input:nth-of-type(3):checked ~ ul #bg2 div,
.w3l-main-slider #slider1 > input:nth-of-type(4):checked ~ ul #bg3 div {
    transform: translate(0);
    transition: 0.5s 0.9s;
}

.w3l-main-slider .banner-top-inner-page {
    // background: url("../images/banner4.jpg") no-repeat center;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -ms-background-size: cover;
    min-height: 250px;
    position: relative;
    z-index: 0;
    display: grid;
    align-items: center;
}

.w3l-main-slider .banner-top-inner-page:before {
    content: "";
    background: rgba(7, 10, 12, 0.66);
    position: absolute;
    top: 0;
    min-height: 100%;
    left: 0;
    right: 0;
    z-index: -1;
}

.decoration-image {
    width: 100%;
    height: 285px;
}

.order-type-title {
    color: $primary;
}

.menu-item-card {
    margin-left: 10px;
    margin-right: 10px;
}

.order-confirmation-stepper {
    margin-left: 30px;
}

.reservation-text {
    color: $primary-dark !important;
    text-align: left;
}

// .order-type-radio{
//   border:1px solid $primary;
//   padding: 5px;
// }

@media (max-width: 992px) {
    .menu-item-card {
        margin-left: 0px;
        margin-right: 0px;
    }

    .order-confirmation-stepper {
        margin-left: 0px;
    }

    .w3l-main-slider h3.gallery4-head {
        font-size: 30px;
        line-height: 37px;
    }
    .box-support {
        padding: 20px 10px;
    }
    .decoration-image {
        width: 100%;
        height: 168px;
    }
    .w3l-main-slider .banner-view {
        min-height: 600px;
    }
}

@media (max-width: 736px) {
    .w3l-main-slider h3.banner-text {
        line-height: 55px;
        font-size: 45px;
    }
    .w3l-main-slider .banner-view {
        min-height: 550px;
    }
}

@media (max-width: 667px) {
    .w3l-main-slider .csslider > .navigation label {
        padding: 20px 15px;
        font-size: 14px;
    }
}

@media (max-width: 600px) {
    .w3l-main-slider h3.gallery4-head {
        margin-bottom: 20px;
    }
    .w3l-main-slider .banner-view {
        min-height: 550px;
    }
}

@media (max-width: 569px) {
    .w3l-main-slider h3.gallery4-head {
        font-size: 26px;
        line-height: 34px;
    }
    .w3l-main-slider h3.banner-text {
        font-size: 40px;
        line-height: 50px;
    }
    .w3l-main-slider .banner-view {
        min-height: 500px;
    }
    .w3l-main-slider .csslider > .navigation label {
        padding: 20px 2px;
        font-size: 12px;
    }
    .w3l-main-slider .banner-top-inner-page {
        min-height: 120px;
    }
    .w3l-main-slider .csslider > .arrows {
        bottom: 15%;
    }
}

@media (max-width: 415px) {
    .w3l-main-slider h3.banner-text {
        font-size: 42px;
        line-height: 40px;
    }
    .w3l-main-slider h5.banner-text {
        font-size: 18px;
    }
}

.w3l-about .new-block {
    display: grid;
    padding: 2rem 0;
}

.w3l-about .img-border:after {
    content: "";
    position: absolute;
    border: 8px solid #eee;
    right: -25px;
    left: 25px;
    bottom: 25px;
    top: -25px;
    z-index: -1;
    border-radius: var(--border-radius);
}

.w3l-about .middle-section h2 {
    color: var(--heading-color);
    font-size: 34px;
    line-height: 1.1;
    font-weight: 600;
}

.w3l-about .middle-section h5 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 10px;
    text-transform: uppercase;
    max-width: 950px;
    letter-spacing: 1px;
}

.w3l-about span.video-play-icon {
    background: #fff;
    display: inline-block;
    width: 55px;
    height: 55px;
    line-height: 55px;
    border-radius: 50%;
    transition: 0.3s ease-in-out;
    -webkit-animation: ripple 1s linear infinite;
    animation: ripple 1s linear infinite;
}

.w3l-about img.video-popup-image {
    border-radius: var(--border-radius);
}

.w3l-about span.video-play-icon span {
    color: var(--primary-color);
}

.w3l-about span.video-play-icon:hover {
    transform: scale(1.1);
    transition: 0.3s ease-in-out;
}

.w3l-about span.fa.fa-play {
    line-height: 55px;
}

.w3l-about .play-view {
    left: 0;
    right: 0;
    top: calc(50% - 40px);
}

@-webkit-keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(76, 119, 224, 0.15), 0 0 0 10px rgba(76, 119, 224, 0.15),
        0 0 0 20px rgba(76, 119, 224, 0.15);
    }
    100% {
        box-shadow: 0 0 0 10px rgba(76, 119, 224, 0.15), 0 0 0 20px rgba(76, 119, 224, 0.15),
        0 0 0 40px rgba(50, 100, 245, 0);
    }
}

@keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(76, 119, 224, 0.15), 0 0 0 10px rgba(76, 119, 224, 0.15),
        0 0 0 20px rgba(76, 119, 224, 0.15);
    }
    100% {
        box-shadow: 0 0 0 10px rgba(76, 119, 224, 0.15), 0 0 0 20px rgba(76, 119, 224, 0.15),
        0 0 0 40px rgba(50, 100, 245, 0);
    }
}

@media (max-width: 1080px) {
    .w3l-about .middle-section h2 {
        font-size: 30px;
    }
    .w3l-about .history-info {
        padding-right: 40px;
    }
}

@media (max-width: 1024px) {
    .w3l-about .middle-section h2 {
        font-size: 28px;
    }
}

@media (max-width: 992px) {
    .w3l-about .img-border {
        display: inline-block;
    }
}

@media (max-width: 800px) {
    .w3l-about .middle-section a {
        margin-top: 40px;
    }
}

@media (max-width: 667px) {
    .w3l-about .middle-section a {
        margin-top: 30px;
    }
}

@media (max-width: 384px) {
    .w3l-about .middle-section a {
        margin-top: 25px;
    }
    .w3l-about .middle-section h2 {
        font-size: 24px;
    }
}


.box-wrap {
    margin-top: 30px;
    padding: 40px 30px;
    transition: all 0.5s ease 0s;
    border-radius: 8px;
    box-shadow: 0 25px 98px 0 rgba(0, 0, 0, 0.03);
    background-color: #fff;
    position: relative;
}

.box-wrap:hover {
    box-shadow: 0 25px 98px 0 rgba(0, 0, 0, 0.1);
}

.box-wrap:hover h4.number {
    opacity: 0.2;
    transition: 0.3s ease-in-out;
}

.box-wrap img {
    max-width: 55px;
    display: block;
    margin: 0 auto;
}

.box-wrap h4 a {
    font-size: 24px;
    font-weight: 600;
    margin-top: 25px;
    margin-bottom: 15px;
    display: block;
    color: var(--heading-color);
}

.box-wrap h4 a:hover {
    color: var(--primary-color);
}

a.read {
    font-size: 17px;
    padding: 0;
    border-bottom: 2px solid var(--primary-color);
    color: var(--heading-color);
    transition: 0.3s ease-in-out;
    font-weight: 600;
    border-radius: 0;
    margin-top: 20px;
    display: inline-block;
    text-transform: capitalize;
}

h4.number {
    position: absolute;
    font-size: 60px;
    line-height: 60px;
    right: 40px;
    top: 30px;
    opacity: 0.07;
    transition: 0.3s ease-in-out;
}

.box-wrap p {
    margin: 0;
    color: var(--para-color);
}

.search-box .icon {
    text-align: center;
    display: block;
    color: #7a7a7a;
}

.icon span.fa {
    color: var(--primary-color);
    font-size: 30px;
}

@media (max-width: 415px) {
    h4.number {
        font-size: 50px;
    }
}

.grids-1 .grids h4 a {
    font-size: 20px;
    line-height: 25px;
    font-weight: 600;
    color: var(--heading-color);
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    display: block;
}

.grids-1 .column {
    box-shadow: 0 25px 98px 0 rgba(0, 0, 0, 0.05);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    transition: 0.3s ease;
    -webkit-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    -ms-transition: 0.3s ease;
    -o-transition: 0.3s ease;
}

.grids-1 .column:hover {
    box-shadow: 0 25px 98px 0 rgba(0, 0, 0, 0.1);
    transform: translateY(-10px);
    transition: 0.3s ease;
    -webkit-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    -ms-transition: 0.3s ease;
    -o-transition: 0.3s ease;
}

.grids-1 .column img {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.grids-1 .column:hover h4 a {
    color: var(--theme-alt-color);
}

.w3l-testimonials .slider-info {
    max-width: 100%;
    margin: 0 auto;
    border-radius: var(--border-radius);
    padding: 3rem;
    border: 1px solid #eee;
    background-color: #eee;
}

.w3l-testimonials .message {
    font-size: 16px;
    line-height: 28px;
    color: #eee;
    margin-top: 20px;
}

.w3l-testimonials .slider-img-info h3 {
    font-size: 26px;
    color: #fff;
    font-weight: 600;
    line-height: 35px;
    text-transform: capitalize;
}

.w3l-testimonials .slider-img-info {
    max-width: 650px;
    margin: auto;
    border: 9px solid rgba(255, 255, 255, 0.2);
    padding: 30px;
    border-radius: var(--border-radius);
}

.w3l-testimonials .banner-view {
    background: url("../images/gallery/slide4.jpg") no-repeat center;
    background-size: cover;
    min-height: 500px;
    position: relative;
    z-index: 0;
    display: grid;
    align-items: center;
    border-radius: var(--border-radius);
}

.w3l-testimonials .banner-top1 {
    background: url("../images/gallery/slide5.jpg") no-repeat center;
    background-size: cover;
}

.w3l-testimonials .banner-top2 {
    background: url("../images/gallery/slide3.jpg") no-repeat center;
    background-size: cover;
}

.w3l-testimonials .banner-top3 {
    background: url("../images/gallery/slide1.jpg") no-repeat center;
    background-size: cover;
}

.w3l-testimonials .banner-view:before {
    content: "";
    background: rgba(7, 10, 12, 0.45);
    position: absolute;
    top: 0;
    min-height: 100%;
    left: 0;
    right: 0;
    z-index: -1;
    border-radius: var(--border-radius);
}

@media (max-width: 1024px) {
    .w3l-testimonials .slider-info {
        padding: 3rem 2rem;
    }
}

@media (max-width: 767px) {
    .w3l-testimonials .slider-info {
        padding: 2rem;
        max-width: 100%;
    }
}

@media (max-width: 480px) {
    .w3l-testimonials .slider-info {
        padding: 2rem 15px;
    }
    .w3l-testimonials .slider-img-info {
        padding: 20px;
    }
    .w3l-testimonials .banner-view {
        min-height: 400px;
    }
    .w3l-testimonials .message {
        font-size: 16px;
        line-height: 28px;
        margin-top: 15px;
    }
}

@media (max-width: 375px) {
    .w3l-testimonials .slider-img-info {
        padding: 10px;
    }
    .w3l-testimonials .slider-info {
        padding: 1rem 5px;
    }
}

.w3l-stats {
    background-color: $primary;
}

.w3l-stats .grids-speci1 {
    padding: 40px 30px;
    transition: all 0.5s ease 0s;
    border-radius: 8px;
    box-shadow: 0 25px 98px 0 rgba(0, 0, 0, 0.03);
    background-color: #fff;
    position: relative;
}

.w3l-stats .grids-speci1 span {
    right: 10px;
    color: var(--secondary-color);
    font-size: 50px;
    position: absolute;
    top: 10px;
    opacity: 0.05;
    transform: scale(1);
    transition: 0.3s ease;
}

.w3l-stats .grids-speci1:hover span.fa {
    opacity: 0.8;
    transform: scale(1.1);
    transition: 0.3s ease;
}

.w3l-stats h3.title-spe {
    color: var(--primary-color);
    font-size: 50px;
    font-weight: 600;
}

.w3l-stats .grids-speci1 p {
    font-size: 17px;
    color: var(--para-color);
}

@media (max-width: 568px) {
    .w3l-stats .main-cont-wthree-fea .col-6 {
        padding: 0 6px;
    }
    .w3l-stats .main-cont-wthree-fea {
        padding: 0 10px;
    }
    .w3l-stats .grids-speci1 span {
        font-size: 40px;
    }
}

@media (max-width: 480px) {
    .w3l-stats .grids-speci p {
        font-size: 15px;
    }
    .w3l-stats .grids-speci1 {
        padding: 30px 20px;
    }
    .w3l-stats h3.title-spe {
        font-size: 40px;
    }
}

@media (max-width: 440px) {
    .w3l-stats .grids-speci1 p {
        font-size: 16px;
    }
}

@media (max-width: 375px) {
    .w3l-stats .grids-speci1 p {
        font-size: 15px;
    }
}

.w3l-footer-29-main {
    background-color: #222;
}

.w3l-footer-29-main option {
    background: #ffffff;
    color: var(--text-color);
}

.w3l-footer-29-main .footer-3 p {
    color: #ababab;
}

.w3l-footer-29-main a.action img {
    margin-left: 4px;
}

.w3l-footer-29-main .align-center {
    text-align: center;
}

.w3l-footer-29-main .footer-29 {
    background: $primary-dark;
}

.w3l-footer-29-main .footer-list-29 .properties a {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 20px;
    margin-bottom: 10px;
}

.w3l-footer-29-main .footer-list-29 p {
    color: #ababab;
}

.w3l-footer-29-main .footer-list-29 .properties a p:hover {
    color: var(--opposite-color);
}

.w3l-footer-29-main .footer-list-29 a p span {
    display: block;
    color: #fff;
    font-weight: bold;
}

.w3l-footer-29-main .footer-list-29 a p {
    color: #fff;
}

.w3l-footer-29-main h6.footer-title-29 {
    color: #fff;
    font-size: 24px;
    line-height: 28px;
    text-transform: capitalize;
    margin-bottom: 25px;
    font-weight: 600;
}

.w3l-footer-29-main .footer-list-29 ul li,
.w3l-footer-29-main .midd-footer-29:nth-child(1) .footer-list-29 ul li:last-child {
    list-style: none;
    margin-bottom: 14px;
}

.w3l-footer-29-main .footer-list-29 ul li:last-child {
    margin-bottom: 0px;
}

.w3l-footer-29-main .footer-list-29 ul li a,
.w3l-footer-29-main .footer-list-29 ul li p {
    font-weight: normal;
    font-size: 17px;
    line-height: 26px;
    color: $lite;
}

.w3l-footer-29-main .footer-4 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
}

.w3l-footer-29-main .footer-list-29 ul li a:hover {
    color: #eee;
}

.w3l-footer-29-main .main-social-footer-29 a {
    margin-right: 10px;
    // color: #ccc;
    // background: #3c3c3c;
    width: 35px;
    height: 35px;
    line-height: 36px;
    display: inline-block;
    text-align: center;
    font-size: 18px;
    border-radius: 50%;
    transition: 0.3s;
}

.w3l-footer-29-main .main-social-footer-29 a:hover {
    transition: 0.3s;
}

.w3l-footer-29-main .main-social-footer-29 a.facebook:hover {
    background: #3b5998 !important;
    color: #fff !important;
    border: 1px dashed $lite !important;
}

.w3l-footer-29-main .main-social-footer-29 a.facebook {
    background: #3b5998;
    color: #fff;
}

.w3l-footer-29-main .main-social-footer-29 a.twitter:hover {
    background: #589541 !important;
    color: #fff !important;
    border: 1px dashed !important;
}

.w3l-footer-29-main .main-social-footer-29 a.twitter {
    background: #589541;
    color: #fff;
}


.w3l-footer-29-main .main-social-footer-29 a.instagram {
    background: #c13584;
    color: #fff;
}

.w3l-footer-29-main .main-social-footer-29 a.instagram:hover {
    background: #c13584 !important;
    color: #fff !important;
    border: 1px dashed $lite !important;
}

.w3l-footer-29-main .main-social-footer-29 a.linkedin:hover {
    background: #0077b5;
    color: #fff;
}

.w3l-footer-29-main .main-social-footer-29 a.google-plus:hover {
    background: #dd4b39;
    color: #fff;
}

.w3l-footer-29-main .footer-list-29 ul li span.fa {
    color: #eee;
    width: 20px;
}

.w3l-footer-29-main .main-social-footer-29 a:last-child {
    margin-right: 0;
}

.w3l-footer-29-main .copy-footer-29 a {
    color: #ccc;
}

.w3l-footer-29-main .copy-footer-29 a:hover {
    color: #eee;
}

.w3l-footer-29-main .list-btm-29 li {
    list-style-type: none;
    display: inline-block;
}

.w3l-footer-29-main ul.list-btm-29 li:nth-child(2) {
    margin: 0 15px;
}

.w3l-footer-29-main .list-btm-29 li a,
.w3l-footer-29-main .list-btm-29 li select {
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    color: #fff;
}

.w3l-footer-29-main .list-btm-29 li a:hover,
.w3l-footer-29-main .list-btm-29 li select:hover {
    color: var(--opposite-color);
}

.w3l-footer-29-main .list-btm-29 li select {
    border: none;
    cursor: pointer;
    background: transparent;
}

.w3l-footer-29-main .bottom-source {
    grid-template-columns: 0.33fr 1fr 1fr 0.5fr;
    grid-gap: 100px;
    margin-top: 60px;
}

.w3l-footer-29-main a.logo-footer {
    color: var(--primary-color);
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    padding: 0;
    display: inline-grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    margin: 0;
    position: relative;
    background: #222;
}

.w3l-footer-29-main a.logo-footer span {
    font-weight: 100;
    color: #eee;
}

.w3l-footer-29-main a.logo-footer:after {
    content: "";
    position: absolute;
    border: 3px solid var(--primary-color);
    width: 60px;
    left: -15px;
    top: -11px;
    bottom: -11px;
    z-index: -1;
    border-radius: 8px;
}

.w3l-footer-29-main .bottom-copies {
    padding: 1.5em 0;
}

.w3l-footer-29-main .footer-29 form {
    display: flex;
}

.w3l-footer-29-main .footer-post a {
    font-weight: 500;
    color: #ccc;
    font-size: 18px;
    line-height: 24px;
    display: inline-block;
}

.w3l-footer-29-main .footer-post a:hover {
    color: #eee;
}

.w3l-footer-29-main .footer-29 .subscribe input[type="email"] {
    background: #2e2e2e;
    border: none;
    padding: 15px 20px;
    font-size: 16px;
    outline: none;
    width: 100%;
    color: #fff;
    border-radius: 0.25rem 0 0 0.25rem;
}

.w3l-footer-29-main p.copy-footer-29 {
    align-self: center;
    color: #ababab;
}

.w3l-footer-29-main .footer-29 .subscribe button {
    background: #2e2e2e;
    padding: 15px 20px;
    padding-left: 0;
    border: none;
    outline: none;
    color: #eee;
    font-size: 16px;
    text-align: right;
    width: 15%;
    border-radius: 0 0.25rem 0.25rem 0;
}

.w3l-footer-29-main #movetop {
    position: fixed;
    bottom: 20px;
    right: 15px;
    z-index: 99;
    font-size: 18px;
    border: none;
    outline: none;
    cursor: pointer;
    color: #fff;
    width: 36px;
    height: 36px;
    background-color: $primary;
    padding: 0;
    line-height: 36px;
    border-radius: 4px;
    transition: 0.3s ease-out;
}

.w3l-footer-29-main #movetop:hover {
    transform: translate3d(0, -5px, 0);
    -webkit-transform: translate3d(0, -5px, 0);
    transition: 0.3s ease-out;
}

@media (max-width: 992px) {
    .w3l-footer-29-main ul.list-btm-29 {
        text-align: center;
        margin-top: 10px;
    }
}

@media (max-width: 568px) {
    .w3l-footer-29-main .footer-4 {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 415px) {
    .w3l-footer-29-main h6.footer-title-29 {
        font-size: 22px;
    }
}

.w3l-services-6-main {
    /*--/features--*/
    /*--//hover--*/
}

.w3l-services-6-main .banner_bottom_left h4 a {
    display: inline-block;
    color: $primary;
    font-size: 34px;
    line-height: 1.1;
    font-weight: 600;
    margin-bottom: 20px;
}

.w3l-services-6-main .serv_sec_info {
    align-items: center;
    color: $primary;
}

.w3l-services-6-main .features-w3pvt-main {
    border-top: 1px solid #f0f3f1;
    margin-top: 4em;
    padding-top: 3em;
}

.w3l-services-6-main .icon_left_grid {
    text-align: center;
}

.w3l-services-6-main .featured_grid_right_info h4 a {
    font-size: 24px;
    color: var(--heading-color);
    font-weight: 600;
    display: inline-block;
    margin-bottom: 10px;
}

.w3l-services-6-main .icon_left_grid span {
    font-size: 2em;
    color: var(--primary-color);
}

.w3l-services-6-main p {
    margin-bottom: 10px;
}

.w3l-services-6-main .features-hny-inner-gd:hover span {
    color: var(--secondary-color);
    transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

@media (max-width: 992px) {
    .w3l-services-6-main .banner_bottom_left h4 a {
        font-size: 30px;
    }
}

@media (max-width: 736px) {
    .w3l-services-6-main .features-w3pvt-main {
        border-top: 1px solid #f0f3f1;
        margin-top: 3em;
        padding-top: 3em;
    }
    .w3l-services-6-main .feature-gird:nth-child(2) {
        margin: 20px 0;
    }
    .w3l-services-6-main .banner_bottom_left.pl-lg-4 {
        margin-top: 40px;
    }
    .w3l-services-6-main .banner_bottom_left h4 {
        font-size: 26px;
        line-height: 30px;
    }
}

@media (max-width: 568px) {
    .w3l-services-6-main .banner_bottom_left h4 a {
        font-size: 28px;
    }
    .w3l-services-6-main .features-w3pvt-main {
        margin-top: 2em;
        padding-top: 2em;
    }
}

@media (max-width: 415px) {
    .w3l-services-6-main .banner_bottom_left h4 a {
        font-size: 24px;
    }
    .w3l-services-6-main .featured_grid_right_info h4 a {
        font-size: 20px;
    }
}

.content-with-photo-16 {
    background-color: #f8f9fa;
}

.content-with-photo-16 .content-with-photo-16-inf h3 {
    font-size: 34px;
    line-height: 1.1;
    font-weight: 600;
    color: var(--heading-color);
    display: inline-block;
    margin-bottom: 20px;
}

.content-with-photo-16 ul.content-photo-list {
    margin-top: 30px;
}

.content-with-photo-16 ul.content-photo-list li {
    list-style-type: disc;
    font-size: 18px;
    line-height: 28px;
    color: var(--heading-color);
    margin-bottom: 10px;
    margin-left: 20px;
    font-weight: 500;
}

@media screen and (max-width: 992px) {
    .content-with-photo-16 .content-with-photo-16-inf h3 {
        font-size: 30px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 640px) {
    .content-with-photo-16 .content-with-photo-16-inf h3 {
        font-size: 28px;
    }
}

@media screen and (max-width: 420px) {
    .content-with-photo-16 .content-with-photo-16-inf h3 {
        font-size: 24px;
        line-height: 28px;
    }
}

@media screen and (max-width: 321px) {
    .content-with-photo-16 .content-with-photo-16-inf h3 {
        font-size: 20px;
    }
}

/*-- team --*/
.w3l-team {
    /* team responsive */
}

.w3l-team .team-grids {
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.w3l-team h4 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    text-transform: capitalize;
    margin-top: 20px;
}

.w3l-team h6 {
    font-size: 17px;
    line-height: 28px;
    color: var(--para-color);
}

.w3l-team .social-icons-section a {
    border-radius: 50px;
    display: inline-block;
    background: rgba(29, 29, 29, 0.7);
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    margin: 0 2px;
}

.w3l-team .social-icons-section a.fac {
    background: #3b5998;
}

.w3l-team .social-icons-section a.twitter {
    background: #1da1f2;
}

.w3l-team .social-icons-section a span.fa {
    line-height: 40px;
}

.w3l-team .team-grids img {
    border-radius: 10px;
    border: 1px solid #dedede;
    width: 100%;
}

.w3l-team .social-icons-section a:hover {
    opacity: 0.8;
}

.w3l-team .team-info {
    position: absolute;
    bottom: -227px;
    margin: 0;
    padding: 2em 0;
    transition: 0.5s;
    -moz-transition: 0.5s;
    width: 100%;
    text-align: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.w3l-team .team-grids:hover div.team-info {
    bottom: 0;
}

.w3l-team .social-icons-section,
.w3l-team .team-grids h6 {
    margin-top: 0.5em;
}

.w3l-team .caption {
    padding: 0px;
}

.w3l-team .social-icons-section {
    padding: 10px;
    margin-top: 10px;
}

@media (max-width: 1080px) {
    .w3l-team .team-grids h4 {
        font-size: 1em;
    }
    .w3l-team .about_grids h3 {
        font-size: 22px;
    }
    .w3l-team .about-in .card {
        padding: 1.5em 0.5em;
    }
}

@media (max-width: 991px) {
    .w3l-team .team-grids img {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .w3l-team .row {
        padding: 0 10px;
    }
    .w3l-team .row .col-6 {
        padding: 0 8px;
    }
    .w3l-team h4 {
        font-size: 22px;
        margin-top: 15px;
    }
}

@media (max-width: 384px) {
    .w3l-team .team .row {
        padding: 0 10px;
    }
    .w3l-team .team .col-6 {
        padding: 0 5px;
    }
}

.ser-bg1 {
    // background: url("../images/gallery/s1".jpg) no-repeat center;
    background-size: cover;
    background-color: $lite;
    min-height: 400px;
    position: relative;
    z-index: 0;
    display: grid;
    align-items: end;
    border-radius: var(--border-radius);
}

.ser-bg2 {
    // background: url("../images/gallery/s2".jpg) no-repeat center;
    background-size: cover;
    background-color: $lite;
    min-height: 400px;
    position: relative;
    z-index: 0;
    display: grid;
    align-items: end;
    border-radius: var(--border-radius);
}


.ser-bg3 {
    // background: url("../images/gallery/s3".jpg) no-repeat center;
    background-color: $lite;
    background-size: cover;
    min-height: 400px;
    position: relative;
    z-index: 0;
    display: grid;
    align-items: end;
    border-radius: var(--border-radius);
}

.ser-bg1:before,
.ser-bg2:before,
.ser-bg3:before {
    content: "";
    // background: rgba(7, 10, 12, 0.6);
    position: absolute;
    top: 0;
    min-height: 100%;
    left: 0;
    right: 0;
    z-index: -1;
    border-radius: var(--border-radius);
}

.img-grids h4 a {
    font-size: 24px;
    font-weight: 600;
    margin-top: 35px;
    margin-bottom: 20px;
    display: block;
    color: #fff;
}

.img-grids h4 a:hover {
    color: var(--primary-color);
}

.img-grids p {
    color: #eee;
}

.img-grids span.fa {
    color: var(--primary-color);
}

.w3l-content-12-main {
    background-color: #f8f9fa;
}

.w3l-content-12-main .content-12 .content-heading {
    color: var(--heading-color);
    line-height: 1.1;
    font-size: 34px;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 20px;
}

.w3l-content-12-main .content-12 {
    align-items: center;
}

.w3l-content-12-main .column {
    text-align: left;
}

.w3l-content-12-main .content-12 section.tab-content {
    display: none;
    padding: 38px 0 0;
}

.w3l-content-12-main .content-12 input {
    display: none;
}

.w3l-content-12-main .content-12 label.tabtle {
    display: inline-block;
    padding: 10px 0px;
    font-weight: 600;
    border: 1px solid transparent;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #101d29;
    margin: 0 15px 0px 0px;
}

.w3l-content-12-main .content-12 label.tabtle:before {
    font-family: fontawesome;
    font-weight: normal;
    margin-right: 10px;
}

.w3l-content-12-main label.tabtle:hover {
    opacity: 0.9;
    cursor: pointer;
}

.w3l-content-12-main .content-12 input:checked + label.tabtle {
    border-bottom: 2px solid var(--primary-color);
    text-align: center;
    color: var(--primary-color);
}

.w3l-content-12-main .content-12 #tab1:checked ~ #content1,
.w3l-content-12-main .content-12 #tab2:checked ~ #content2,
.w3l-content-12-main .content-12 #tab3:checked ~ #content3,
.w3l-content-12-main .content-12 #tab4:checked ~ #content4 {
    display: block;
}

@media all and (max-width: 1024px) {
    .w3l-content-12-main .content-12 label.tabtle {
        font-size: 19px;
    }
}

@media screen and (max-width: 992px) {
    .w3l-content-12-main .content-12 .content12 {
        grid-template-columns: 1fr;
    }
    .w3l-content-12-main .content-12 .content12 {
        margin-top: 0px;
    }
    .w3l-content-12-main .content-12 .content-para {
        max-width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .w3l-content-12-main .content-12 label.tabtle:before {
        margin: 0;
        font-size: 18px;
    }
    .w3l-content-12-main .content-12 .content-heading {
        line-height: 35px;
        font-size: 30px;
        margin-bottom: 16px;
    }
}

@media screen and (max-width: 600px) {
    .w3l-content-12-main .content-12 .grid-col-4 {
        grid-row-gap: 20px;
    }
}

@media screen and (max-width: 440px) {
    .w3l-content-12-main .content-12 .grid-col-4 {
        grid-row-gap: 10px;
        grid-column-gap: 10px;
    }
    .w3l-content-12-main .content-12 section.tab-content {
        padding: 28px 0 0;
    }
    .w3l-content-12-main .content-12 .content-heading {
        line-height: 30px;
        font-size: 24px;
    }
}

@media screen and (max-width: 414px) {
    .w3l-content-12-main .content-12 label.tabtle {
        font-size: 16px;
    }
}

.w3l-covers-1 .d-grid {
    display: grid;
}

.w3l-covers-1 .cover {
    background: url("../images/gallery/allergy_img.jpg") no-repeat center;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -ms-background-size: cover;
    padding: 70px 0;
    position: relative;
    z-index: 0;
}

.w3l-covers-1 .cover:before {
    content: "";
    background: rgba(16, 16, 16, 0.43);
    position: absolute;
    top: 0;
    min-height: 100%;
    left: 0;
    right: 0;
    z-index: -1;
}

.w3l-covers-1 .cover-content {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
}

.w3l-covers-1 .cover h3 {
    color: #fff;
    font-size: 32px;
    line-height: 40px;
}

.w3l-covers-1 .read-more.black {
    background: var(--theme-color);
    border: 2px solid var(--theme-color);
    color: #fff;
    padding: 12px 35px;
    display: inline-block;
    font-size: 16px;
    outline: none;
    border-radius: 35px;
}

@media (max-width: 1080px) {
    .w3l-covers-1 .cover h3 {
        font-size: 30px;
        line-height: 35px;
    }
}

@media (max-width: 737px) {
    .w3l-covers-1 .cover-content {
        grid-template-columns: 1fr;
        grid-gap: 20px;
        display: block;
    }
    .w3l-covers-1 a.read-more {
        margin-top: 15px;
    }
}

@media (max-width: 568px) {
    .w3l-covers-1 .cover {
        padding: 50px 0;
    }
}

.w3l-contact-2 .contact-grids {
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
}

.w3l-contact-2 .contact-right {
    padding-left: 28px;
}

.w3l-contact-2 .contact-left {
    padding-right: 28px;
}

.w3l-contact-2 .contact-left h4 {
    font-size: 45px;
    line-height: 55px;
    color: var(--heading-color);
    margin-bottom: 20px;
    font-weight: 700;
}

.w3l-contact-2 .contact-left h6 {
    font-size: 18px;
    line-height: 28px;
    color: var(--para-color);
}

.w3l-contact-2 .hours p a {
    font-size: 18px;
    color: var(--para-color);
}

.w3l-contact-2 .hours p a:hover {
    color: var(--primary-color);
}

.sliderVideo {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    transition: all 1.2s linear;
    opacity: 0;
    z-index: -10;
}


.w3l-contact-2 .contact-left h6.info {
    font-size: 20px;
    line-height: 28px;
    color: var(--heading-color);
}

.w3l-contact-2 .contact-grids input,
.w3l-contact-2 .contact-grids textarea {
    width: 100%;
    color: var(--para-color);
    background: #fff;
    font-size: 16px;
    line-height: 25px;
    font-weight: normal;
    font-style: normal;
    border: none;
    font-family: inherit;
    padding: 15px;
    border: none;
    border: 1px solid #ddd;
    outline: none;
    border-radius: 0px;
    margin-bottom: 16px;
    border-radius: 4px;
}

.MuiListItemText-primary {
    font-family: "Cairo" !important;
    font-size: 20px !important;
}

.MuiTypography-h4 {
    font-family: "Cairo" !important;
}

.MuiTypography-h3 {
    font-family: "Cairo" !important;
}

.w3l-contact-2 .contact-grids textarea {
    height: 120px;
    margin: 0 0 20px 0;
}

.w3l-contact-2 .contact-grids input:focus,
.w3l-contact-2 .contact-grids textarea:focus {
    border: 1px solid var(--primary-color);
}

@media (max-width: 992px) {
    .w3l-contact-2 .contact-right {
        padding-left: 0px;
        margin-top: 2em;
    }
    .w3l-contact-2 .contact-grids {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 800px) {
    .w3l-contact-2 .contact-left h4,
    .w3l-contact-2 .contact-left h3 {
        font-size: 40px;
        line-height: 50px;
    }
}

@media (max-width: 600px) {
    .w3l-contact-2 .contact-left h4,
    .w3l-contact-2 .contact-left h3 {
        font-size: 30px;
        line-height: 38px;
    }
}

@media (max-width: 480px) {
    .w3l-contact-2 .contact-left h4,
    .w3l-contact-2 .contact-left h3 {
        font-size: 28px;
        line-height: 34px;
    }
}

.SnackbarItem-message {
    font-size: 18px !important;
}

@import "../../assets/css/variables.module";

.ItemGroupCard {
  margin-bottom: 10px;
}

.MenuItem {
  margin-bottom: 10px;
  border-radius: 10px !important;
}

.item-card {
  margin-left: 40px;
  margin-right: 40px;
}

.CardContent{
  padding:0px !important;
}

.ItemNameAndPriceContainer {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ItemPriceBox {
  // display: flex;
  justify-content: flex-end;
  align-items: center;
}

.CategoryName {
  font-size: 30px !important;
  margin-top: 5px;
  margin-bottom: 8px !important;
}

.item-price {
  font-size: 20px;
  font-weight: 600;
  border: 1px solid $primary;
  padding: 4px;
  border-radius: 25px;
  margin-bottom: 5px;
  white-space: nowrap;
}

.ItemName {
  font-size: 26px !important;
  margin-bottom: 10px !important;
}

.mobileMenuCategoryDiv {
  margin-bottom: 8px;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.categoryHeading {
  background-color: $primary !important;
  border-radius: 5px;
  padding: 10px !important;
  border-radius: 5px !important;

}

.category-text {
  font-size: 24px !important;
  color: $lite;
}

.group-item-title{
  font-size: 25px !important;
    color: white;
}

.group-item-details-div{
  border: 1px solid $primary;
}

.category-details{
  border: 1px solid $primary-dark;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 8px 5px 0px !important;
}

@media (max-width: 1200px) {
  .CategoryName {
    margin-top: 5px;
    margin-bottom: 8px;
  }
  .ItemName {
    font-size: 20px !important;
  }
  .ItemDescription {
    font-size: 16px !important;
    margin: 3px auto !important;
    text-align: start;
  }
}

.price-icon-div {
  text-align: right;
}

.AddItemToCart {
  font-size: 50px !important;
  color: $primary;
  text-align: right !important;

  &:hover {
    cursor: pointer;
    //  color: $primary-dark3;
    //  transform: scale(0);
  }
}

.MenuItemGroup {
  margin-bottom: 15px;
}

.MenuItemGroupIcon {
  color: $lite;
}

.MenuItemGroupItemCount {
  //color: $lite;
  display: flex;
  align-items: center;
  margin-left: 20px;
}

@media (max-width: 992px) {
  .item-card {
    margin-left: 0px;
    margin-right: 0px;
  }

  .ItemNameAndPriceContainer {
    padding: 5px;
  }

  .item-price {
    font-size: 16px;
    font-weight: 600;
    border: 1px solid $primary;
    padding: 4px;
    border-radius: 25px;
    margin-bottom: 5px;
    white-space: nowrap;
  }

  .AddItemToCart {
    font-size: 55px !important;
    color: $primary;
    text-align: right !important;

    &:hover {
      cursor: pointer;
      //  color: $primary-dark3;
      //  transform: scale(0);
    }
  }

  .ItemDescription {
    font-size: 16px !important;
    margin: 3px auto !important;
    text-align: start;
  }

  .ItemName {
    font-size: 21px !important;
  }
}

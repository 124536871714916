@import "Error404Page";
@import "src/assets/css/variables.module";

.error-container {

    #upSide {
        background-color: $primary-dark;

        .error-code {
            color: #D8D8D8;
        }
    }

    #downSide {
        background-color: $primary;

        .back-btn:not(:hover) {
            color: yellow !important;
            border-color: yellow;
        }

        & > * {
            color: $background-light !important;
        }
    }
}

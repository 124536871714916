@import "../../assets/css/variables.module.scss";

.additional-item-box{
  margin-top: 20px;
}

.title{
  margin-left: 10px;
  margin-bottom: 10px;
  color: $primary;
  //font-size: 22px !important;
}

.setMenuBody{
  width: 500px;
}

.setMenuItemSize{
    height: 600px;
    overflow-x: hidden;
    overflow-y: auto;
}

.additional-item{
  font-size: 16px;
  padding: 2px;
  color: $primary-dark;
}

.selected-addons{
  background-color: $primary !important;
}

.additional-item-price{
  font-size: 16px;
  margin-right: 2px;
  color: $primary-dark;
}

.priceBox{
  font-size: 17px;
  color: $primary;
}

.backButton{
  margin-right: 6px !important;
}

.buttonDiv{
  white-space: nowrap;
}

.setMenuItem{
  font-size: 18px !important;
  color: $primary;
}

.summary-item{
    font-size: 18px !important;
    color: $primary-dark;
}

.summary-type{
    font-size: 25px !important;
    color: $primary;
    margin-bottom: 3px;
}

.summary-addon{
    font-size: 14px !important;
    color: $primary;
    margin-left: 5px;
}

.setMenuPrice{
  font-size: 18px !important;
  color: $primary;
}

@media (max-width: 992px) {
  .setMenuBody {
      width: 320px;
      max-height: 450px;
  }
  .priceBox{
    font-size: 14px;
    color: $primary;
  }
}

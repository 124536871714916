$lite: #fff;
$primary: #CA0603;
//$primary: #ef5c24;
$primary2: #ef5c24;
$primary2-border: #da1741;
$primary-dark: #141414;
$primary-dark2: rgba(237, 88, 120, 0.5);
$primary-dark3: rgba(234, 58, 96, 0.25);
$box-shadow: 0 0 0 0.2rem;
$background-light: #F6F6F6;
$background-signature-dish: #ef5c24;
$bottom-nav-bg: #CA0603;


@import "../../../assets/css/variables.module.scss";

.orderTypeDiv{
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px;
  border-radius: 20px !important;
}

.OrderTypeRadioContainer{
  margin-bottom: 10px !important;
}

.total-div{
  padding: 15px;
  color: $primary !important;
  border-radius: 10px !important;

}

.total-text{
  font-size: 18px;
}

.total-price{
  font-size: 19px;
}
@import "./variables.module.scss";

.about-description {
    color: $lite !important;
}

.reservationSection {
    background-color: $lite;
}

.backgroundDark {
    background-color: $background-light;
}

.backgroundSignatureDish {
    background-color: $background-signature-dish;
}

.about-title {
    font-family: 'Lora', serif;
    font-size: 45px !important;
    font-weight: 600;
}

.filter-items {
    border-bottom: 1px solid #e8dede;
}

.error-box {
    width: 100%;
    height: 100px;
    position: absolute;
    top:0;
    bottom: 50%;
    left: 0;
    right: 0;
    margin: auto;
}

.category-name-font {
    font-family: "Cairo", sans-serif !important;
}

.promotional-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 15px !important;
}

.modalTitleCloseButton {
    cursor: pointer;
    color: $lite;
    position: absolute;
    top: 0;
    background-color: $primary-dark;
    right: 0;
    border: 1px solid $lite;
    border-radius: 50px;
}

.trip-advisor {
    width: 25px;
    color: $lite !important;
}

.promotional-title {
    padding: 10px !important;
}

.promotional-image {
    width: 390px;
    height: 500px;
}

.promotional-btn {
    margin-right: 7px !important;
}

.reservation-title {
    font-family: "Dancing Script", sans-serif;
    font-size: 55px !important;
    color: $primary;
}

h3.title-big {
    font-family: "Lora", sans-serif;
    font-size: 37px !important;
}

.point-circular {
    margin: 100px 200px 80px;
}

.fugaz-font {
    font-family: "Lora", sans-serif;
    font-size: 37px !important;
}

.w3l-footer-29-main h6.footer-title-29 {
    font-family: "Lora", sans-serif;
    font-size: 28px !important;
    font-style: italic;
}

.exo2 {
    font-family: "Exo 2", sans-serif;
    font-style: italic;
    font-size: 40px !important;
}

.profile-padding {
    padding: 15px;
    font-size: 20px;
}

.profile-margin {
    margin-bottom: 5px;
}

.footer-text {
    color: $primary;
}

.reservation-hear-about-text {
    color: $primary !important;
    font-size: 20px;
    margin-top: 12px;
    font-weight: bold !important;
}

.point-text {
    display: flex;
    justify-content: center;
    font-size: 25px !important;
    color: $primary !important;
    margin-top: 100px !important;
    margin-bottom: 80px !important;
}

.point-value {
    font-size: 45px !important;
    color: $primary !important;
}

.no-payment-text {
    color: $primary !important;
}

.point-link {
    font-size: 16px !important;
    margin-top: 20px !important;
}
.timeMsg {
    font-size: 13px !important;
    color: $primary;
}

.pop-over {
    color: $lite;
    cursor: pointer;
    font-size: large;
}

.pop-over-text {
    font-size: 18px !important;
    padding: 5px !important;
}

.hover-pointer {
    cursor: pointer;
}


@media (max-width: 992px) {
    .promotional-image {
        width: 100%;
        height: 100%;
    }
}


.MuiTypography-h3 {
    font-size: 35px;
}

.reviewHover:hover {
    cursor: grab;
}

.MuiBadge-colorPrimary {
    background-color: $primary-dark !important;
}


@media (max-width: 1200px) {
    .MuiGrid-container {
        align-items: baseline;
        justify-content: space-between;
    }
}

@media (max-width: 1200px) {
    .login-form-center {
        margin-left: auto !important;
        margin-right: auto !important;
    }
}


.home-services {
    background-image: url("../images/gallery/image8.png");

    /* Set a specific height */
    min-height: 500px;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    position: relative;
    z-index: 2;
    // background-repeat: no-repeat;
    // background-size: cover;
    background-color: $primary-dark;

    &::after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: $primary-dark, $alpha: .4);
        z-index: -1;
    }
}


.parallax-food-section {
    //background-image: url("../images/gallery/background-image-rajdoot.jpg");
    background-color: $primary-dark;
    /* Set a specific height */
    min-height: 500px;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.parallax-allergy-section {
    background-image: url("../images/gallery/backgroun_image_rajdoot4.png");

    /* Set a specific height */
    min-height: 300px;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    position: relative;
    z-index: 2;
    // background-repeat: no-repeat;
    // background-size: cover;
    background-color: $primary-dark !important;

    &::after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: $primary-dark, $alpha: .2);
        z-index: -1;
    }
}

.policy-text p{
    text-align: justify;
    margin-top: 7px !important;
    margin-bottom: 5px !important;
}

.policy-text h2{
    margin-bottom: 10px !important;
}

@import "~react-datepicker/dist/react-datepicker.css";
@import "../../../assets/css/variables.module.scss";

$elementWidth: 150px;
$elementHeight: calc(280px + (1.7rem / 2));
$elementPadding: 5px;
$headerBg: $primary;
$headerFontSize: 20px;
$itemFontSize: 18px;
$itemColor: #3F51B5;
$itemSelectedColor: #3F51B5;
$scrollbarWidth: 5px;
$scrollbarTackBg: #F5F5F5;
$scrollbarBg: $scrollbarTackBg;
$scrollbarThumbBg: #3F51B5;


.react-datepicker__time-list-item--disabled {
  display: none;
}


.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box{
  width:$elementWidth;
 
}

//timepicker item list
.react-datepicker{
  font-size: $itemFontSize;
  font-weight: 600;
  color: $primary;
}

//time picker header width 
.react-datepicker__header{
  width: $elementWidth;
}

//timepicker scrollbar width
.react-datepicker__time-list::-webkit-scrollbar
{
  width: $scrollbarWidth;
  background-color: rgb(226, 219, 219) ;
}

//timepicker thumb color
.react-datepicker__time-list::-webkit-scrollbar-thumb
{
  background-color:  $primary2;
}

//timepicker header
.react-datepicker-time__header{
  font-size: $headerFontSize;
  font-weight: 700;
  color: $primary;
  
}

//timepicker full list 
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item{
  padding: 0px;
  margin: 2px;
}

//timepicker item selection
.react-datepicker__time-list-item--selected{
  background-color: $primary2 !important;
}

.react-datepicker__time{
  width: $elementWidth;
}

.react-datepicker__time-container{
  width: auto;
}


.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list{
  height: $elementHeight;
}
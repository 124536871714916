$fontFamily: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";

.error-container {
    font-family: $fontFamily;

    #upSide {
        height: 50vh;
        background-color: rgb(255, 255, 255);
        display:flex;
        justify-content:center;
        align-items: end;

        .error-code{
            font-family: $fontFamily;
            font-size: 200px;
            font-weight: 700;
            line-height: 75%;
            color: #D8D8D8;
            margin-bottom: 0.5rem;
        }
    }

    #downSide {
        font-family: $fontFamily;
        height: 50vh;
        background-color: #D8D8D8;
        display:flex;
        justify-content:first baseline;
        align-items: center;
        flex-direction: column;

        .error-msg {
            font-family: $fontFamily;
            font-size: 50px;
            margin-top: 50px;
            margin-bottom: 0.5rem;
        }

        .error-description {
            font-family: $fontFamily;
            color: rgb(33, 37, 41);
        }
    }
}
